
a:hover {
    text-decoration: none;
}

.nav a {
  font-family: 'Montserrat', sans-serif;
  //text-transform: uppercase;

  -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
}

nav {
    box-shadow: 0 0 15px -5px black;
    z-index: 999;
}

.active {
    color: darken(@brand-primary, 10%) !important;
}

.head-image {
  background-size: cover;
  //background-position: center;
    background-position: right;
  height: 550px !important;
}

.head-image-repeat {

}

.title {
  background-color: fade(@navbar-etc, 70%);
  padding: 20px;
  margin: 40px 0;
  color: @banner-text-color;
    border-radius: 4px;
  h1 {
    margin-top: 0;
  }
}


.btn-square {
    border: 2px solid @button-color;
    border-radius: 2px;
    background: transparent;
    color: @button-color;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    float: right;
}

.btn {
    font-family: 'Montserrat', sans-serif;

    -webkit-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -o-transition: 0.1s all;
    transition: 0.1s all;
}

.btn-square:hover, .btn-square:active {
    background-color: @button-color;
    color: fade(@navbar-etc, 80%);
}

hr {
    border: 1px solid @hr-color;
    margin: 10px 0;
}

.index-tiles {
   text-align: left;
   background-color: @navbar-etc;
   width: 100%;
   position: relative;
   height: auto;
   padding: 20px 0;

   .tile {
      padding: 40px 20px;
      display: block;
   }

   a.tile {
        color: @text-color;
        text-decoration: none;

        -webkit-transition: 0.4s color;
        -moz-transition: 0.4s color;
        -o-transition: 0.4s color;
        transition: 0.4s color;
   }

   a.tile:hover, a.tile:active {
       text-decoration: none;
       color: @brand-primary;//lighten(@text-color, 25%);
   }

   .tile-img {
      background-color: @brand-primary;
      text-align: center;
      float: left;
      margin-right: 20px;
      height: 100px;
      width: 100px;
      border-radius: 50px;
      overflow: hidden;

      span {
         margin-top: 25px;
         color: @navbar-etc;
         font-size: 50px;
      }



   }

   h5 {
      font-size: 25px;

      @media (max-width: 1200px) {
         font-size: 20px;
      }
   }

   p {
      overflow: hidden;
   }
}
@media screen and (max-width: 1200px) {
    .index-tiles {

        a.tile {
            text-align: center !important;
            padding: 10px 20px;

        }

       .tile-img {
          text-align: center;
          margin: 0 auto;
          float: none;


          span {
             margin-top: 25px;
          }
       }

       h5 {
          clear: both;
       }
    }
}

// Adjust footer on mobile
@media screen and (max-width: @screen-sm) {
    .footer {
         .text-center {
             text-align: left;
         }
         .text-right {
             text-align: left;
         }
    }
}

.banner-text {
    background-color: @brand-primary;
    color: @navbar-etc;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px ;
    text-align: center;

    .container {
        padding: 10px;
    }

    a {
        color: @navbar-etc;
    }
}

.main-content {
    padding: 30px 0;
}

.footer {
    background-color: @footer-bg;
    padding: 0;//15px 0 0 0;
    color: @footer-text-color;
    box-shadow:inset 0 0 15px -5px black;
    //border-top: solid 1px darken(@brand-primary, 5%);

    h3 {
      color: @footer-headings-color;
    }


    div.footer-address {
      p {
        line-height: @footer-address-line-height !important;
      }
    }

    div.footer-contact {
      p {
        line-height: @footer-contact-line-height !important;
      }
    }

    div.footer-quick-links {
      ul {
        li {
          line-height: @footer-quick-links-line-height !important;

        }
      }
    }

    div.footer-credits {
      background-color: darken(@footer-bg, 5%);
      color: @footer-credits-color !important;
      a {
        color: @footer-credits-link-color !important;
      }
    }

    div.designed-by img {

    }

    hr {
        border-color: @footer-hr-color;
        margin: 5px 0 10px 0;
    }
}

.footer a {
    color: @footer-link-color;
}

.copyright {
	padding-top: 15px;
}

.social img {
    margin: 5px;
}

.owl-pagination {
    text-align: left;
}


 .owl-controls {
     .container
 }

 .contact #message {
     display: none;
 }

 .hidden-verif {
    position: absolute;
    top: -20px;
    opacity: 0;
    cursor: none;
    height: 0;
 }

 .gallery-thumb {
     border: solid 2px @navbar-etc;
     cursor:pointer;
 }

.gallery-thumb:hover {
     opacity: 0.7;
 }

 blockquote strong {
    .h4;
 }

 .areas-covered {
     display: inline-block;
     padding: 0;
 }

 .areas-covered li {
    display: inline-block;
    float: left;
    padding: 15px;
    margin: 5px;
    .label;
    .label-primary;
    .h4;
    color: white;
 }

 .tradelogos {
    text-align: center;
 }

 .tradelogos img {
    margin: 15px;
 }

 .gallerynav {
    margin: 20px 0;
 }

 .gallerynav a {
    margin-right: 10px;
 }

 // Overide for gallery banners
 .text-left {
    text-align: left;
 }


 // Projects Page
 .project-tile {
   font-size: 16px;
   padding: 10px;
   line-height: 1.6;
   margin-bottom: 20px;

   a {
     transition: all 0.2s;
   }

   hr {
      border: none;
      height: 1px;
      /* Set the hr color */
      color: @brand-primary; /* old IE */
      background-color: @brand-primary; /* Modern Browsers */
   }

  img {
    width: 100%;
    margin-bottom: 10px;
  }

  .project-name-label {

  }
  .project-name {
    font-weight: bold;
  }
 }


 // Reviews Section

 div.reviews {
    padding: 25px 0;
    width: 100%;
    background: @brand-primary;
    div.review {
        position: relative;
        background: rgba(255,255,255, 0.7);
        padding: 10px 20px;
        border-radius: 6px;
        box-shadow: 0 0 10px -4px black;

        p.comment {
            min-height: @index-reviews-min-height;
            position: relative;

            a {
              position: absolute;
              right: 0;
              bottom: 0;
            }
        }

        p.author {
            font-size: 16px;
        }

        div.rating {
            color: @brand-primary;
            text-align: center;
            padding: 10px;
            font-size: 20px;
            border-bottom: 1px solid rgba(255,255,255,0.4);
            margin-bottom: 10px;

            span {
                padding: 0 3px;
            }
        }

        a.read-more {
            float: right;
        }
    }

    @media only screen and (max-width: 798px) {
        div.review {
            margin-bottom: 20px;
        }
    }
}

// Divider

.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}


.top-bar {
    height: 25px;
    background-color: @brand-primary;
    a {
        color: white;
    }

    .top-bar-right {
        text-align: right;
    }
}

div.navbar-logo {
    padding-left: 15px;
}
div.navbar-contact {
    padding-right: 15px;
    margin-top: 10px;
    text-align: right;
    a {
        display: block;
        font-size: 26px;
    }
}

@media only screen and (max-width: 600px) {
    div.navbar-logo {
        text-align: center;
        padding-left: 0 !important;
        img {
            margin: 0 auto;
        }
    }
    div.navbar-contact {
         margin-top: 0px;
         text-align: center;
         padding-right: 0 !important;

    }
}



// Gallery

.gallery-img {
    margin-bottom: 20px;
    text-align: center;
    img {
        width: 100%;
        height: auto;
    }
}

div.pp_social {
    display: none !important;
}

.navbar-toggle {
    margin-top: 30px;
}

.link-box {
    p {
        padding: 20px;
        background-color: lighten(@brand-primary, 50%);
        border-radius: 5px;
        border: 1px solid lighten(@brand-primary, 40%);
    }
}
